@use '../../../../layout/variables';
@use '../../../../ui/styles/typography';

.steps {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 1rem;

  &.center {
    justify-content: center;
  }

  .arrowIcon {
    align-self: start;
    margin-top: 2.375rem;
  }

  .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
    padding: 0 0.84rem;
    min-width: 0;

    .stepTitle {
      color: var(--text-secondary);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
      text-align: center;
      display: block;
    }

    .stepLabel {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
      text-align: center;
    }
  }
}

[dir='rtl'] {
  .steps {
    .arrowIcon {
      transform: rotate(180deg);
    }
  }
}
