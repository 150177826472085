@use '../../ui/styles/typography';
@use '../../layout/variables';

.hasBorderBottom {
  border-bottom: 1px solid var(--contrast-low);
}

.expandable {
  background-color: var(--background-secondary);

  td {
    border-radius: 0 0 variables.$radius-xlarge variables.$radius-xlarge !important;
  }

  td:hover {
    background-color: var(--background-secondary);
  }
}

.expanded {
  padding-inline: 1.5rem !important;
  position: relative;
  top: -20px;
}

.container {
  display: flex;
  flex-direction: row;
  background-color: var(--background);
  border-radius: variables.$radius-medium;
}

.leftContainer {
  width: 50%;
}

.secondaryTextSmall {
  color: var(--text-secondary);
}

.table {
  table-layout: fixed;

  td {
    word-wrap: break-word;
    overflow-wrap: break-word;

    .cellText {
      max-width: 300px;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
