@use '../../../layout/variables';
@use '../../../ui/styles/typography';

.iBReferralLink {
  width: 100%;

  .iBReferralLinkList {
    width: 100%;

    .iBReferralLinkItem {
      width: 100%;
      margin-top: 1rem;

      .iBReferralLinkCopyField {
        width: 100%;
        margin-top: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 1.875rem;
        gap: 0.625rem;
        background-color: var(--background-content);
        padding: 0.25rem 0.13rem 0.25rem 0.75rem;

        .iBReferralLinkCopyValue {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
  }
}
