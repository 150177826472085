@use '../../../../ui/styles/typography';
@use '../../../../layout/variables';

.label {
  color: var(--text-secondary);
  text-transform: capitalize;
  @include typography.textTiny;
}

.fieldWrapper {
  padding: 0.375rem 1rem;
  background-color: var(--background);
  border-radius: variables.$radius-medium;
}

.additionalNote {
  margin-bottom: 1rem;
}

.additionalText {
  padding: 0.25rem 1rem 0 1rem;
  margin-bottom: 0.75rem;
}

.resetMargin {
  margin: 0 !important;
}

.marginTop {
  margin-top: 0.25rem;
}
