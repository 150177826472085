@use '../../ui/styles/typography';
@use '../../layout/variables';

.rangeOptions {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0.75rem 0;
  border-bottom: 1px solid var(--background-divider);

  .tabsWrapper {
    display: flex;
    white-space: nowrap;

    .tab {
      margin: 0;
    }
  }
}
