@use '../../../../layout/variables';
@use '../../../../ui/styles/typography';

$scrollContainerFadeEnd: linear-gradient(
  270deg,
  var(--background) 0%,
  rgba(255, 255, 255, 0) 116.17%
);
$scrollContainerFadeEndDark: linear-gradient(
  270deg,
  var(--background) 0%,
  rgba(11, 15, 25, 0) 100%
);
$scrollContainerFadeStart: linear-gradient(
  90deg,
  var(--background) 0%,
  rgba(255, 255, 255, 0) 116.17%
);
$scrollContainerFadeStartDark: linear-gradient(
  90deg,
  var(--background) 0%,
  rgba(11, 15, 25, 0) 100%
);

:root {
  --scroll-container-fade-end: #{$scrollContainerFadeEnd};
  --scroll-container-fade-start: #{$scrollContainerFadeStart};
}

[data-theme='dark'] {
  --scroll-container-fade-end: #{$scrollContainerFadeEndDark};
  --scroll-container-fade-start: #{$scrollContainerFadeStartDark};
}

.scrollContainerWrapper {
  width: 100%;
  position: relative;

  .scrollContainer {
    width: 100%;
    display: flex;
    overflow: visible;

    .scrollContainerInner {
      flex: 1;
    }
  }
}

.scrollContainerFadeEnd {
  &::after {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 7rem;
    pointer-events: none;
    background: var(--scroll-container-fade-end);
  }
}

.scrollContainerFadeStart {
  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 7rem;
    pointer-events: none;
    background: var(--scroll-container-fade-start);
    z-index: 1;
  }
}

.containerScrollable {
  .scrollContainer {
    overflow-x: auto;

    .scrollContainerInner {
      display: flex;
      white-space: nowrap;
    }
  }
}
