@use '../../layout/variables';
@use '../../ui/styles/typography';

.message {
  margin-top: -1px;
  background-color: var(--primary);

  .innerWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  p {
    @include typography.textSmallStrong;
    padding-inline: 1rem;
    color: var(--text-on-primary-color);
  }

  .readMore {
    @include typography.textSmall;
    margin-inline-start: 0.5rem;
    color: var(--text-on-primary-color);
    cursor: pointer;
  }
}
