@use '../../../../ui/styles/typography';

.toggleFilter {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-width: 275px;

  .name {
    color: var(--text);
  }
  .buttonItem {
    display: inline-block;
    margin: 0.5rem;
  }
}
