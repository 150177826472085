@use '../../ui/styles/typography';
@use '../../layout/variables';

.radioGroupWrapper {
  display: flex;
  flex-direction: column;
}

.radioGroupWrapper.isRadioButtonHorizontal {
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.errorMessage {
  color: var(--error);
}

.wrapper {
  display: flex;

  .radioButton {
    position: relative;
    height: 1.5rem;

    &.disabled {
      cursor: default;
    }

    .input {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      opacity: 0;
      cursor: pointer;

      &:disabled {
        cursor: default;
      }
    }

    .input + .icon .radioOn {
      display: none;
    }

    .input + .icon .radioOff {
      display: block;
    }

    .input:checked + .icon .radioOn {
      display: block;
    }

    .input:checked + .icon .radioOff {
      display: none;
    }

    .icon {
      cursor: pointer;

      .radioOn {
        :global(.border) {
          stroke: var(--contrast-high);
          fill: none;
        }

        :global(.dot) {
          stroke: none;
          fill: var(--contrast-high);
        }
      }

      .radioOff {
        :global(.dot) {
          stroke: var(--contrast-high);
          fill: none;
        }
      }

      &.disabled {
        cursor: default;

        .radioOn {
          :global(.border) {
            stroke: var(--disabled);
            fill: none;
          }

          :global(.dot) {
            stroke: none;
            fill: var(--disabled);
          }
        }

        .radioOff {
          :global(.dot) {
            stroke: var(--disabled);
            fill: none;
          }
        }
      }
    }
  }

  .contentWrapper {
    margin-inline-start: 1rem;

    .label {
      display: block;
    }

    .hint {
      display: block;
      color: var(--text-secondary);
    }

    &.disabled {
      cursor: default;
      color: var(--text-secondary);
    }
  }
}
