@use '../../ui/styles/typography';
@use '../../layout/variables';

.wrapper {
  padding: 3rem 0;
  .subtitle {
    margin-top: 1.5rem;
    color: var(--text-secondary, #868686);
  }
}

.responsiveIframe {
  margin-top: 2rem;
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  height: 0;
  overflow: hidden;
  @include variables.mobile {
    width: 100%;
    padding-bottom: 1024px;
  }
}

.iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  opacity: 0;
  transition: opacity 0.3s ease;

  &.loaded {
    opacity: 1;
  }
}

.loadingContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--background-primary, #ffffff);
}
