@use '../../../../ui/styles/typography';
@use '../../../../layout/variables';

.currencyFieldWrapper {
  width: 8.75rem;
}

.textSmall {
  @include typography.textSmall;
}
