@use '../../ui/styles/typography';

.form {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .uploadSection {
    display: flex;
    flex-direction: column;
    padding-top: 1.5rem;
    gap: 1.5rem;

    .label {
      display: flex;
      align-items: center;
      gap: 0.25rem;
    }

    .error {
      color: var(--error);
    }

    .docList {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      align-self: stretch;

      .documentItem {
        display: flex;
        align-items: flex-start;
        gap: 0.5rem;
        align-self: stretch;

        input[type='checkbox'] {
          margin-inline-end: 0;
        }
      }
    }

    .documentListButton {
      padding: 0.5rem;
      color: var(--text-on-action-secondary);

      .lessMore {
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }
    }

    .checkboxWrapper {
      width: 100%;
      min-width: 0;

      .checkboxContentWrapper {
        width: 100%;
        min-width: 0;

        .documentName {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    .fileUpload {
      width: 100%;
    }
  }
}
