@use '../../ui/styles/typography';
@use '../../layout/variables';

$labelOffset: 0.75rem;
$borderWidth: 1px;

.field {
  position: relative;

  &.fullWidth {
    width: 100%;
  }

  &::after {
    content: none !important;
  }

  :global(label) {
    color: var(--text-secondary);
    z-index: 10;
    top: 8px;
    left: 12px;
    position: absolute;
    background: transparent;
    text-align: start;
    max-width: calc(100% - $labelOffset - 2 * $borderWidth);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    [dir='rtl'] & {
      left: auto;
      right: 12px;
    }
  }

  .datePickerWrapper {
    width: 100%;
  }

  .datePickerCalendar {
    :global(.react-datepicker__header) {
      h2 {
        line-height: 1;
      }
    }
  }

  .label {
    @include typography.textTiny;
    text-align: start;
  }

  .labelSecondary {
    @include typography.textTiny;
    color: var(--text-secondary);

    &.error {
      color: var(--error);
    }

    &.disabled {
      background-color: var(--contrast-low);
    }
  }

  .textareaLabel {
    top: 0.375rem;
    left: 0.75rem;
    width: calc(100% - 2rem);
    padding-bottom: 0.125rem;
    background-color: var(--background-secondary);
  }

  .input {
    color: var(--text);
    min-height: 3.5rem;
    height: auto;
    resize: none;
    border-width: $borderWidth;
    border-radius: variables.$radius-medium;
    border-color: var(--contrast-medium-low);
    background-color: var(--background-secondary);
    @include typography.text;

    @include variables.mobile() {
      @include typography.text;
    }

    &.dynamicHeight {
      overflow-y: hidden;
    }

    &.inputFocus {
      padding-top: 20px;
    }

    &:disabled {
      color: var(--disabled);
      background-color: var(--contrast-low);
    }

    &.inputError {
      border: 2px solid var(--error);
      text-indent: -1px;
    }

    &.isIconLeft {
      padding-inline-start: calc(#{variables.$icon-size} * 3);
    }

    &.isIconRight {
      padding-inline-end: calc(#{variables.$icon-size} * 3);
    }

    &:-webkit-autofill ~ label {
      background-color: transparent;
    }
  }

  textarea {
    &::placeholder {
      color: var(--text-secondary);
    }
  }

  &:has(:global(label)) {
    .input {
      padding-top: 1.25rem;
    }
  }

  :global(input::placeholder) {
    color: var(--text-secondary);
  }

  .icon {
    display: block;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);

    &.iconLeft {
      left: variables.$icon-size;
      right: variables.$icon-size;

      &[dir='rtl'] {
        left: auto;
        right: variables.$icon-size;
      }

      &.inputFocus {
        padding-top: 10px;
      }
    }

    &.iconRight {
      right: variables.$icon-size;

      &[dir='rtl'] {
        right: auto;
        left: variables.$icon-size;
      }
    }
  }

  .hint {
    color: var(--text-secondary);
    margin-top: 0.25rem;
    margin-inline-start: 0.75rem;
    display: block;
  }

  .errorMessage {
    color: var(--error);
    text-align: start;
    margin-top: 0.5rem;
    text-indent: 12px;
    display: block;
  }
}

.formTextField {
  .offsetlessLabel {
    left: 0;

    [dir='rtl'] & {
      right: 0;
    }
  }

  .input {
    border: none;
    box-shadow: none;
    padding-inline-start: 0;
  }
}
