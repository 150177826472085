@use '../../ui/styles/typography';
@use '../../layout/variables';

:global(.modal-card-head) {
  border-color: var(--contrast-low);
}

.body {
  @include typography.text;
  color: var(--text);
  background-color: var(--background-secondary);
  padding: 1rem 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  :global(input):focus-visible {
    outline: none;
  }

  .filterWrapper {
    margin-bottom: 2rem;
  }
}
.footer {
  background-color: var(--background-secondary);
  button {
    color: var(--text);
  }
  @include variables.mobile() {
    @include typography.textSmall;
  }
}
