@use '../../layout/variables';

@mixin button-bg($bg, $bg-hover, $color-hover, $bg-disabled, $color, $bg-active: '') {
  background-color: $bg;

  &:hover:enabled {
    color: $color-hover;
    background-color: $bg-hover;
    transition: all 0.3s ease;
  }

  &:focus {
    color: $color;
    box-shadow: none !important;
  }

  &:active {
    color: $color;
    background-color: $bg-active !important;
  }

  &.disabled {
    color: var(--disabled);
    background-color: $bg-disabled;
  }

  &:disabled {
    color: var(--disabled);
    background-color: $bg-disabled;
  }
}
