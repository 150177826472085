@use '../../layout/variables';
@use '../../ui/styles/typography';

@mixin badge($color, $border-color) {
  @include typography.textTiny;
  display: inline-flex;
  box-sizing: border-box;
  text-transform: capitalize;
  padding: 0.25rem 0.5rem;
  color: $color;
  border: 1px solid $border-color;
  border-radius: variables.$radius-small;
}

.primary {
  @include badge(var(--primary), var(--primary));
}

.secondary {
  @include badge(var(--contrast-low), var(--contrast-low));
}

.tertiary {
  @include badge(var(--text-interactive), var(--text-interactive));
}

.active,
.approved,
.success {
  @include badge(var(--success), var(--success));
}

.successNew,
.surfaceSuccess {
  @include badge(var(--success-new), var(--success-new));
}

.surfaceSuccess {
  @include typography.textSmallStrong;
  background-color: variables.$surface-success;
  border: 0;
}

.pending,
.warning {
  @include badge(var(--warning), var(--warning));
}

.cancelled,
.declined,
.failed,
.rejected,
.reversed,
.danger {
  @include badge(var(--error), var(--error));
}

.new {
  @include badge(var(--primary), var(--primary));
  text-transform: uppercase;
}

.completed,
.ended,
.expired,
.light {
  @include badge(var(--disabled), var(--disabled));
}

.demo {
  display: inline-flex;
  box-sizing: border-box;
  text-transform: capitalize;

  font-size: 0.75rem;
  padding: 0.25rem 0.5rem;
  line-height: 1rem;

  color: variables.$white;
  background-color: variables.$blue;
  border: none;
  border-radius: variables.$radius-small;
}

.live {
  display: inline-flex;
  box-sizing: border-box;
  text-transform: uppercase;

  font-size: 0.75rem;
  padding: 0.25rem 0.5rem;
  line-height: 1rem;

  color: variables.$white;
  background-color: var(--primary);
  border: none;
  border-radius: variables.$radius-small;
}

.inactive {
  display: inline-flex;
  box-sizing: border-box;
  text-transform: uppercase;

  font-size: 0.75rem;
  padding: 0.25rem 0.5rem;
  line-height: 1rem;

  color: variables.$white;
  background-color: variables.$disabled;
  border: none;
  border-radius: variables.$radius-small;
}
