@use '../../ui/styles/mixins';
@use '../../layout/variables';

.btn {
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  height: auto;
  color: inherit;

  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }

  &[disabled],
  &fieldset[disabled] {
    background-color: initial;
    border-color: initial;
    opacity: initial;
  }

  &:hover {
    color: inherit;
  }
}

.btn * {
  cursor: pointer;
}

.selectable {
  color: var(--text);
  border: none;
  border-radius: 50%;
  padding: 0.5rem 0.75rem;
  @include mixins.button-bg(
    transparent,
    var(--contrast-low),
    var(--text),
    transparent,
    var(--text),
    var(--press)
  );
}

.hoverable {
  display: flex;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: variables.$radius-full;
  width: fit-content;

  &:hover {
    background: var(--contrast-low);
  }
}

.fullWidth {
  width: 100%;
}
