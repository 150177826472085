@use '../ui/styles/typography';
@import './variables';
@import '~bulma/bulma.sass';
@import '~bulma-tooltip/dist/css/bulma-tooltip.min.css';

@font-face {
  font-family: 'Inter Variable';
  src: url('~@fontsource-variable/inter/files/inter-latin-standard-normal.woff2') format('woff');
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Noto Sans Display Variable';
  src: url('~@fontsource-variable/noto-sans-display/files/noto-sans-display-latin-standard-normal.woff2')
    format('woff');
  font-style: normal;
  font-stretch: normal;
}

// DO NOT use this class, there is a hook useDisableMainScroll, you can use that
.blockedScroll {
  overflow: hidden;
}

#chat-widget-container {
  z-index: map-get($zIndex, chatWidget) !important;
}

#hubspot-messages-iframe-container {
  z-index: map-get($zIndex, chatWidget) !important;
}

html {
  --font: 'Inter Variable';
  --font-arabic: 'Noto Sans Display Variable';
  scroll-behavior: smooth;
}
html[data-theme='theme-light'] {
  --primaryBackground: var(--background);
  --primaryTextColor: #000;
}

html[data-theme='theme-dark'] {
  --primaryBackground: #121212;
  --primaryTextColor: #ffffff;
}

html {
  width: calc(100vw - var(--scrollbar-width));
}

strong {
  color: var(--text);
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1;
  letter-spacing: 0.2px;
  display: flex;
  flex-direction: column;
  background: var(--background);
  color: var(--text);

  > * {
    flex-grow: 0;
  }

  > .expander {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background: var(--background) !important;
    > * {
      flex-grow: 0;
      background: var(--background) !important;

      &:last-child {
        flex-grow: 1;
      }
    }
  }

  &,
  button,
  input,
  select,
  textarea {
    font-family: var(--font), -apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif;
  }
}

.localeArabic {
  font-family: var(--font-arabic), sans-serif !important;

  :is(button, input, select, textarea) {
    font-family: inherit !important;
  }
}

p a {
  text-decoration: underline;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

svg {
  overflow: visible;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  background: var(--background);
  > * {
    flex-grow: 0;
  }

  > * {
    flex-grow: 0;
  }

  > .expander {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  > .defaultBackground {
    background: var(--background);
  }

  > .secondaryBackground {
    background: var(--background-secondary);
  }

  main {
    padding-bottom: 6rem;
    display: flex;
    flex-direction: column;
  }
}

.container {
  width: 100%;
  padding: 0 $block-spacing-mobile;

  &.section {
    padding: $block-spacing;
  }
}

.content {
  word-break: break-word;
}

.is-expanded {
  word-break: break-word;
}

.has-cursor-pointer {
  cursor: pointer;
}

.control.has-icons-left .icon,
.control.has-icons-right .icon {
  height: 100% !important;
  z-index: unset;
}

.is-link {
  color: var(--text-interactive) !important;
  border: none;
  cursor: pointer;
  background-color: transparent;
}

.is-right {
  text-align: end !important;
}

.has-error {
  color: var(--error);
}

.text-hero {
  @include typography.textHero;
}

.text-large {
  @include typography.textLarge;
}

.text-large-strong {
  @include typography.textLargeStrong;
}

.text {
  @include typography.text;
}

.text-primary {
  color: var(--text);
}

.text-secondary {
  color: var(--text-secondary);
}

.text-strong {
  @include typography.textStrong;
}

.text-small {
  @include typography.textSmall;
}

.text-small-strong {
  @include typography.textSmallStrong;
}

.text-tiny {
  @include typography.textTiny;
}

.text-tiny-strong {
  @include typography.textTinyStrong;
}

.text-micro {
  @include typography.textMicro;
}

.text-align-center {
  text-align: center;
}

.text-align-start {
  text-align: start;
}

.text-primary {
  color: var(--text);
}
.primary-text {
  color: var(--primary);
}

.background-primary {
  background: var(--background);
}

.modal-wrapper {
  position: relative;

  > header {
    background-color: var(--contrast-low);

    > p {
      @include typography.textLargeStrong;
      color: var(--text);
    }
  }

  > footer {
    justify-content: flex-end;
    border-top: 1px solid var(--contrast-low);
    padding: 1rem;
    background-color: var(--background);

    @include mobile() {
      padding: 0.5rem;
    }

    button {
      @include typography.text;
      border: none;
      background-color: transparent;
      color: var(--text);
      &:hover {
        color: var(--text-secondary);
      }

      @include mobile() {
        padding: 0 0.5rem;
      }

      &:last-child {
        @include typography.textSmallStrong;
      }
    }
  }
}

.is-inactive {
  box-shadow: none;
  opacity: 0.5;
}

.is-borderless {
  border: none;

  &:focus-visible {
    border: none !important;
    outline: none;
  }
}

//Override bulma spacing
.mr-0 {
  margin-inline-end: 0 !important;
}

.ml-0 {
  margin-inline-start: 0 !important;
}

.mr-1 {
  margin-inline-end: 0.25rem !important;
}

.ml-1 {
  margin-inline-start: 0.25rem !important;
}

.mr-2 {
  margin-inline-end: 0.5rem !important;
}

.ml-2 {
  margin-inline-start: 0.5rem !important;
}

.mr-3 {
  margin-inline-end: 0.75rem !important;
}

.ml-3 {
  margin-inline-start: 0.75rem !important;
}

.mr-4 {
  margin-inline-end: 1rem !important;
}

.ml-4 {
  margin-inline-start: 1rem !important;
}

.mr-5 {
  margin-inline-end: 1.5rem !important;
}

.ml-5 {
  margin-inline-start: 1.5rem !important;
}

.mr-6 {
  margin-inline-end: 3rem !important;
}

.ml-6 {
  margin-inline-start: 3rem !important;
}

.pr-0 {
  padding-inline-end: 0 !important;
}

.pl-0 {
  padding-inline-start: 0 !important;
}

.pr-1 {
  padding-inline-end: 0.25rem !important;
}

.pl-1 {
  padding-inline-start: 0.25rem !important;
}

.pr-2 {
  padding-inline-end: 0.5rem !important;
}

.pl-2 {
  padding-inline-start: 0.5rem !important;
}

.pr-3 {
  padding-inline-end: 0.75rem !important;
}

.pl-3 {
  padding-inline-start: 0.75rem !important;
}

.pr-4 {
  padding-inline-end: 1rem !important;
}

.pl-4 {
  padding-inline-start: 1rem !important;
}

.pr-5 {
  padding-inline-end: 1.5rem !important;
}

.pl-5 {
  padding-inline-start: 1.5rem !important;
}

.pr-6 {
  padding-inline-end: 3rem !important;
}

.pl-6 {
  padding-inline-start: 3rem !important;
}
[dir='rtl'] .has-text-right {
  text-align: end !important;
}
[dir='rtl'] .has-text-left {
  text-align: start !important;
}

//theme
:root {
  --always-white: #{$always-white};
  --background: #{$background};
  --background-light: #{$background};
  --background-gray: #{$background-gray};
  --background-divider: #{$background-divider};
  --background-modal: #{$background-modal};
  --background-bg: #{$background-bg};
  --background-bg2: #{$background-bg2};
  --foreground-primary: #{$foreground-primary};
  --contrast: #{$contrast};
  --surface-secondary: #{$surface-secondary};
  --contrast-hint: #{$contrast-hint};
  --contrast-max: #{$contrast-max};
  --contrast-high: #{$contrast-high};
  --contrast-medium: #{$contrast-medium};
  --contrast-medium-low: #{$contrast-medium-low};
  --contrast-low: #{$contrast-low};
  --contrast-min-light: #{$contrast-min};
  --contrast-min: #{$contrast-min};
  --text: #{$text};
  --text-secondary: #{$text-secondary};
  --text-interactive: #{$text-interactive};
  --text-positive: #{$text-positive};
  --text-disabled: #{$text-disabled};
  --text-neutral: #{$text-neutral};
  --surface-information: #{$surface-information};
  --text-inverse: #{$text-inverse};
  --text-on-primary-color: #{$text-on-primary-color};
  --primary: #{$primary};
  --primary-focused: #{$primary-focused};
  --primary-disabled: #{$primary-disabled};
  --background-secondary: #{$background-secondary};
  --success-new: #{$success-new};
  --success: #{$success};
  --warning: #{$warning};
  --warning-bg: #{$warning-bg};
  --select-blue: #{$select-blue};
  --error: #{$error};
  --surface-error: #{$surface-error};
  --surface-disabled: #{$surface-disabled};
  --surface-dark: #{$surface-dark};
  --gold: #{$gold};
  --diamond: #{$diamond};
  --disabled: #{$disabled};
  --blue: #{$blue};
  --interactive-hover: #{$interactive-hover};
  --press: #{$press};
  --icon-positive: #{$icon-positive};
  --icon-transaction: #{$icon-transaction};
  --icon-transfer: #{$icon-transfer};
  --icon-tertiary: #{$icon-tertiary};
  --icon-secondary: #{$icon-secondary};
  --ib-transaction-history-skeleton-box: #{$ib-transaction-history-skeleton-box};
  --ib-transaction-history-skeleton-wrapper: #{$ib-transaction-history-skeleton-wrapper};
  --background-content: #{$background-content};
  --state-background-hover: #{$state-background-hover};
  --state-hover: #{$state-hover};
  --state-active: #{$state-active};
  --state-press: #{$state-press};
  --background-chart-yellow: #{$background-chart-yellow};
  --background-chart-green: #{$background-chart-green};
  --background-chart-blue: #{$background-chart-blue};
  --state-background-active: #{$state-background-active};
  --state-background-press: #{$state-background-press};
  --state-table-hover: #{$state-table-hover};
  --background-selected: #{$background-selected};
  --background-accent-red: #{$background-accent-red};
  --progress-bar-primary: #{$progress-bar-primary};
  --progress-bar-secondary: #{$progress-bar-secondary};
  --progress-bar-blue: #{$progress-bar-blue};
}

[data-theme='dark'] {
  --always-white: #{$always-white};
  --background: #{$background-dark};
  --background-gray: #{$background-gray-dark};
  --background-divider: #{$background-divider-dark};
  --background-modal: #{$background-modal-dark};
  --background-bg: #{$background-bg-dark};
  --background-bg2: #{$background-bg2-dark};
  --foreground-primary: #{$foreground-primary-dark};
  --surface-secondary: #{$surface-secondary-dark};
  --contrast: #{$contrast-dark};
  --contrast-hint: #{$contrast-hint-dark};
  --contrast-max: #{$contrast-max-dark};
  --contrast-high: #{$contrast-high-dark};
  --contrast-medium: #{$contrast-medium-dark};
  --contrast-medium-low: #{$contrast-medium-low-dark};
  --contrast-low: #{$contrast-low-dark};
  --contrast-min: #{$contrast-min-dark};
  --text: #{$text-dark};
  --text-secondary: #{$text-secondary-dark};
  --text-interactive: #{$text-interactive-dark};
  --text-positive: #{$text-positive-dark};
  --text-disabled: #{$text-disabled-dark};
  --text-neutral: #{$text-neutral-dark};
  --surface-information: #{$surface-information-dark};
  --text-inverse: #{$text-inverse-dark};
  --text-on-primary-color: #{$text-on-primary-color-dark};
  --primary: #{$primary-dark};
  --primary-focused: #{$primary-focused-dark};
  --primary-disabled: #{$primary-disabled-dark};
  --background-secondary: #{$background-secondary-dark};
  --success-new: #{$success-new-dark};
  --success: #{$success-dark};
  --warning: #{$warning-dark};
  --warning-bg: #{$warning-bg-dark};
  --select-blue: #{$select-blue-dark};
  --error: #{$error-dark};
  --surface-error: #{$surface-error-dark};
  --surface-disabled: #{$surface-disabled-dark};
  --gold: #{$gold-dark};
  --diamond: #{$diamond-dark};
  --disabled: #{$disabled-dark};
  --press: #{$press-dark};
  --icon-positive: #{$icon-positive-dark};
  --icon-transaction: #{$icon-transaction-dark};
  --icon-transfer: #{$icon-transfer-dark};
  --icon-tertiary: #{$icon-tertiary-dark};
  --icon-secondary: #{$icon-secondary-dark};
  --ib-transaction-history-skeleton-box: #{$ib-transaction-history-skeleton-box-dark};
  --ib-transaction-history-skeleton-wrapper: #{$ib-transaction-history-skeleton-wrapper-dark};
  --background-content: #{$background-content-dark};
  --state-background-hover: #{$state-background-hover-dark};
  --state-hover: #{$state-hover-dark};
  --state-active: #{$state-active-dark};
  --state-press: #{$state-press-dark};
  --background-chart-yellow: #{$background-chart-yellow-dark};
  --background-chart-green: #{$background-chart-green-dark};
  --background-chart-blue: #{$background-chart-blue-dark};
  --state-background-active: #{$state-background-active-dark};
  --state-background-press: #{$state-background-press-dark};
  --state-table-hover: #{$state-table-hover-dark};
  --background-selected: #{$background-selected-dark};
  --background-accent-red: #{$background-accent-red-dark};
  --progress-bar-primary: #{$progress-bar-primary-dark};
  --progress-bar-secondary: #{$progress-bar-secondary-dark};
  --progress-bar-blue: #{$progress-bar-blue-dark};
}

ol,
ul {
  list-style-position: inside;
}
