@use '../../../ui/styles/typography';
@use '../../../layout/variables';

// If you change this, also change bubbleOffset in useInfoCardMove.tsx
$bubble-margin: 10px;

.bubbleWrapper {
  max-width: 100%;
  display: inline-block;
  position: relative;
  cursor: pointer;

  svg {
    display: flex;
  }
}

.bubble {
  position: absolute;
  inset-inline-start: 50%;
  transform: translateX(-50%);
  z-index: 100;
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  text-wrap: wrap;
  overflow-y: visible;
  height: auto;
  width: max-content;
  text-align: start;
  pointer-events: none;
  cursor: initial;

  &:dir(rtl) {
    transform: translateX(50%) !important;
  }

  &.active {
    pointer-events: initial;
  }

  &.top {
    bottom: calc(100% + $bubble-margin);
    inset-inline-start: 50%;
    transform: translateX(-50%) !important;

    &:dir(rtl) {
      transform: translateX(50%) !important;
    }
  }

  &.right {
    inset-inline-start: calc(100% + $bubble-margin);
    top: 50%;
    transform: translateX(0) translateY(-50%) !important;
  }

  &.bottom {
    top: calc(100% + $bubble-margin);
    inset-inline-start: 50%;
    transform: translateX(-50%) !important;

    &:dir(rtl) {
      transform: translateX(50%) !important;
    }
  }

  &.left {
    inset-inline-start: auto;
    inset-inline-end: calc(100% + $bubble-margin);
    top: 50%;
    transform: translateX(0) translateY(-50%) !important;
  }

  &.topRight {
    bottom: calc(100% + $bubble-margin);
    inset-inline-start: $bubble-margin;
    transform: none !important;
  }

  &.topLeft {
    bottom: calc(100% + $bubble-margin);
    inset-inline-end: $bubble-margin;
    inset-inline-start: auto;
    transform: none !important;
  }

  &.bottomRight {
    top: calc(100% + $bubble-margin);
    inset-inline-start: $bubble-margin;
    transform: none !important;
  }

  &.bottomLeft {
    top: calc(100% + $bubble-margin);
    inset-inline-end: $bubble-margin;
    inset-inline-start: auto;
    transform: none !important;
  }
}
