@use '../../ui/styles/typography';
@use '../../layout/variables';

.calendarHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .calendarNavigation {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    width: 3rem;
    height: 3rem;
    background-color: transparent;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      background-color: var(--state-hover);
    }

    &:active {
      background-color: var(--state-press);
    }

    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
}
