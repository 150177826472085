@use '../../layout/variables';
@use '../../ui/styles/typography';

.wrapper {
  @include variables.mobile() {
    margin-top: 1rem;
  }
}

.cards {
  margin: 1.5rem 0;

  .card {
    display: flex;
    align-items: center;
    background-color: var(--background-secondary);
    padding: 0 5px;
    border-radius: variables.$radius-xlarge;
    box-shadow: 0 0.5rem 2.5rem rgba(0, 0, 0, 0.04);
    margin: 0.5rem 0;
  }
}
