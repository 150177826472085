@use '../../../../ui/styles/typography';

.notice {
  @include typography.text;
  margin-top: 1rem;
  margin-bottom: 1.5rem;

  .title {
    color: var(--primary);
  }

  .strong {
    @include typography.textStrong;
  }
}
