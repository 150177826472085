@use '../../../ui/styles/typography';
@use '../../../layout/variables';

.title {
  @include typography.h2;
  color: var(--contrast-high);
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: var(--contrast-low);
  border-radius: variables.$radius-xlarge;
  padding: 1rem;
}

.contentText {
  color: var(--contrast-medium-low-dark);
  display: flex;
  align-items: center;
  flex-direction: row;
  text-align: start;
}

.link,
.link:hover {
  @include typography.textSmallStrong;
  color: var(--primary);
  cursor: pointer;
  text-decoration-line: underline;
}
