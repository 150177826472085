.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1.5rem;

  .setupStatusText {
    text-align: center;
  }

  .nextButton {
    padding: 0 6rem;

    .buttonContent {
      display: flex;
      align-items: center;

      .arrowIcon {
        padding-inline-start: 0.5rem;
        padding-top: 0.35rem;
      }
    }
  }
}
