@use '../../ui/styles/typography';

.textHero {
  @include typography.textHero;
}

.textH1 {
  @include typography.h1;
}

.textH2 {
  @include typography.h2;
}

.textH3 {
  @include typography.h3;
}

.textH4 {
  @include typography.h4;
}

.textLarge {
  @include typography.textLarge;
}

.textLargeStrong {
  @include typography.textLargeStrong;
}

.text {
  @include typography.text;
}

.textStrong {
  @include typography.textStrong;
}

.textSmall {
  @include typography.textSmall;
}

.textSmallStrong {
  @include typography.textSmallStrong;
}

.textTiny {
  @include typography.textTiny;
}

.textTinyStrong {
  @include typography.textTinyStrong;
}

.textMicro {
  @include typography.textMicro;
}
